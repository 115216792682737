<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card>
                <basic-table :columns="columns" :data="items" v-slot="props">
                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button variant="success" class="ml-1" @click="enableReceptionPanel(props.row.id)"
                                  v-if="props.row.status === 0 || props.row.status === 2">
                            <feather-icon icon="PlayIcon"/>
                        </b-button>
                        <b-button variant="warning" class="ml-1" @click="disableReceptionPanel(props.row.id)"
                                  v-if="props.row.status === 1">
                            <feather-icon icon="PauseIcon"/>
                        </b-button>
                        <b-button variant="warning" class="ml-1" @click="openEditReceptionPanel(props.row)">
                            <feather-icon icon="EditIcon"/>
                        </b-button>
                        <b-button variant="danger" class="ml-1" @click="removeItem(props.row.id)">
                            <feather-icon
                                icon="Trash2Icon"
                            />
                        </b-button>
                    </div>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>
            </b-card>
        </b-overlay>

        <b-modal title="Edit Reception Panel" v-model="editModalActive" no-close-on-backdrop>
            <template #default>

                <b-form-group>
                    <label for="nameEdit">Name:</label>
                    <b-form-input
                        id="nameEdit"
                        type="text"
                        placeholder="Name"
                        v-model="editObject.name"
                    />
                </b-form-group>
                <b-form-group>
                    <label for="columnsEdit">Columns:</label>
                    <b-form-input
                        id="columnsEdit"
                        type="number"
                        placeholder="3"
                        v-model="editObject.columns"
                    />
                </b-form-group>
            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="editModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="warning" @click="editItem">
                    <feather-icon
                        icon="EditIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Save</span>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>

    import {BOverlay, BCard, BButton, BModal, BFormInput, BFormGroup} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'

    export default {
        components: {
            BOverlay,
            BCard,
            BButton,
            BModal,
            BFormInput,
            BFormGroup,
            BasicTable
        },
        data() {
            return {

                items: [],
                dataLoaded: false,

                statuses: [
                    {
                        id: 0,
                        name: 'Waiting'
                    },
                    {
                        id: 1,
                        name: 'Enabled'
                    },
                    {
                        id: 2,
                        name: 'Disabled'
                    }
                ],

                columns: [
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Name',
                            filterFn: (data, filter) => (data.toLowerCase().includes(filter.toLowerCase()))
                        }
                    },
                    {
                        label: 'Status',
                        displayType: 0,
                        field: status,
                        formatFn: (value) => this.statuses[value].name,
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search',
                            filterFn: (data, filter) => (this.statuses[data].name.toLowerCase().includes(filter.toLowerCase()))
                        }

                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],
                editObject: {},
                editModalActive: false

            }
        },
        methods: {
            openEditReceptionPanel(receptionPanel) {
                this.editObject = JSON.parse(JSON.stringify(receptionPanel))
                this.editModalActive = true
            },
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/reception_panels/')
                loadPromise.then(function(response) {
                    thisIns.items = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                Promise.all([loadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })

            },
            editItem() {
                const thisIns = this
                this.editObject.columns = Number(this.editObject.columns)
                const loadPromise = this.$http.put(`/api/management/v1/reception_panels/${this.editObject.id}`, this.editObject)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Reception Panel saved')
                    thisIns.editModalActive = false
                    thisIns.editObject = {}
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            removeItem(id) {
                const thisIns = this
                const loadPromise = this.$http.delete(`/api/management/v1/reception_panels/${id}`)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Reception Panel removed')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            enableReceptionPanel(id) {
                const thisIns = this
                const loadPromise = this.$http.put(`/api/management/v1/reception_panels/${id}/enable`)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Reception Panel enabled')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            disableReceptionPanel(id) {
                const thisIns = this
                const loadPromise = this.$http.put(`/api/management/v1/reception_panels/${id}/disable`)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Reception Panel disabled')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            statusRenderer(statusId) {
                return this.statuses.find(status => status.id === statusId)
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
